<template>
  <div>

    <el-divider content-position="left">代理链接</el-divider>
    <span style="display: block;width: 100%;text-align: center" v-if="status==1"><h3>{{url}}</h3></span>
    <span style="display: block;width: 100%;text-align: center" v-if="status==-1"><h3>非代理账号</h3></span>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      url:'',
      status:'',
    }
  },
  created() {
    this.agenturl();
  },
  methods: {
    async agenturl() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/system/agenturl')
      if (data) {
        if (data.code === 200) {
          this.url = data.data.url;
          this.status = data.data.status;
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    }
  }
}
</script>
